import { defineStore } from 'pinia'
export const useArrDepStore = defineStore({
  id: 'arrDepStore',
  state: () => ({
    depDateDisplay: '',
    arrDateDisplay: ''
  }),
  actions: {
    setDepDateDisplay(value: string) {
      this.depDateDisplay = value
    },
    setArrDateDisplay(value: string) {
      this.arrDateDisplay = value
    }
  }
})
